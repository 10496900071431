export default {
  computed: {
    user() {
      if (!this.$auth.user) return null
      const user = this.$auth.user
      console.log('userjs', user)
      return {
        name: user.given_name + ' ' + user.family_name,
        nickname: user.nickname,
        email: user.email,
        profileUrl: '/user/' + user.nickname,
        picture: user.picture,
      }
    },

    loggedIn() {
      return this.$auth?.loggedIn
    },

    localeRoutePrefix() {
      return this.$i18n.locale === this.$i18n.defaultLocale
        ? '/'
        : '/' + this.$i18n.locale + '/'
    },

    userProfileMenuItems() {
      if (!this.user) return []

      const routes = this.$router.options.routes
      const items = []

      const userProfileRoutes = routes.filter((route) => {
        const start = this.localeRoutePrefix + 'user'
        return (
          route.meta.group === 'userProfile' && route.path.startsWith(start)
        )
      })
      const dashboardRoute = routes.find((route) => {
        const start = this.localeRoutePrefix + 'dashboard'
        return route.meta.name === 'Dashboard' && route.path.startsWith(start)
      })

      if (dashboardRoute) {
        items.unshift({
          url: this.$localeResolver.localePath({ path: dashboardRoute.path }),
          title: this.$translation('menu.' + dashboardRoute.meta.name),
          icon: dashboardRoute.meta.icon,
        })
      }
      userProfileRoutes.forEach((route) => {
        items.push({
          url: this.$localeResolver
            .localePath({ path: route.path })
            .replace(':user', this.user.nickname),
          title: this.$translation('menu.' + route.meta.name),
          icon: route.meta.icon,
        })
      })

      return items
    },

    userSettingsMenuItems() {
      if (!this.user) return []

      const routes = this.$router.options.routes
      const items = []

      const userSettingsRoutes = routes.filter((route) => {
        const start = this.localeRoutePrefix + 'user'
        return (
          route.meta.group === 'userSettings' && route.path.startsWith(start)
        )
      })

      userSettingsRoutes.forEach((route) => {
        items.push({
          url: this.$localeResolver
            .localePath({ path: route.path })
            .replace(':user', this.user.nickname),
          title: this.$translation('menu.' + route.meta.name),
          icon: route.meta.icon,
        })
      })
      return items
    },
  },
}
